import React from "react";
import "./eigthsection.css";
import moneybag from "../../../../assets/Images/Home/moneybag.svg";
import goals from "../../../../assets/Images/Home/goals.svg";
import momandson from "../../../../assets/Images/Home/momandchild.jpg";

const Eightsection = () => {
  return (
    <section className="eigth-section">
      <div>
        <div className="eight-section-content">
          <div>
            <span>You can carefully invest in Corporate </span>
            <span style={{ color: "#58B135" }}>bonds.</span>
          </div>
          <div className="eight-content">
            <div>
              <img src={moneybag} alt="moneybag" />
            </div>
            <div>Start investing at just ₹5,000.</div>
          </div>
          <div className="eight-content">
            <div>
              <img src={goals} alt="goals" />
            </div>
            <div>Carefully Corporate bonds giving 12-24% returns</div>
          </div>
        </div>
        <div className="eigth-section-image">
          <img src={momandson} alt="momandson" />
        </div>
      </div>
    </section>
  );
};

export default Eightsection;
