import React from "react";
import { bonds, retiredapp } from "../../../../assets/Func/navigations";
import "./twelfthsection.css";

const Twelfthsection = () => {
  return (
    <section className="twelfth-section">
      <div>
        <div>The People and Platform that Make a Difference</div>
        <p>
          Debt instruments offered on the platform are supporting small
          businesses that create jobs, stimulate local economies, and promote
          projects. With Retired App we serve the people in our community,
          improve and help businesses and investors profit together.
        </p>
        <p>
          The highly qualified team of finance specialists at Retired app spend
          countless hours analyzing the smallest details to bring the best bond
          investments to you.
        </p>
        <div className="twelfth-last">
          <div>
            <span style={{ color: "#58B135" }}>Explore Bonds </span>
            <div>Diversify your portfolio.</div>
          </div>
          <div>
            <div className="twelfth-last-btn" onClick={retiredapp}>
              Sign up{" "}
            </div>
            <div className="twelfth-last-btn" onClick={bonds}>
              Explore Bonds
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Twelfthsection;
