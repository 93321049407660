import React from "react";
import "./eleventhsection.css";

import chart from "../../../../assets/Images/Home/chart.jpg";

const Eleventhsection = () => {
  return (
    <section className="eleventh-section">
      <div>
        <div className="eleventh-content">
          <div>
            Bonds on <span style={{ color: "#58B135" }}>Retired App</span> vs
            Other assets
          </div>
          <div>
            Bond funds are a stable, liquid and safe way to invest, and our
            bonds are carefully curated to provide you with the smoothest
            returns.
          </div>
        </div>
        <div>
          <img src={chart} alt="chart" />
        </div>
      </div>
    </section>
  );
};

export default Eleventhsection;
