import React, { useState, useEffect } from 'react'
import {
    NewUIMyEachInput, NewDateAndPicker,
    NewEachCustomDropdown, NewEachCustomImageUploadcustom,

} from "./GloabelInput";
import { useHistory, useLocation } from "react-router-dom";
import { Children } from 'react/cjs/react.production.min';
import axios from 'axios';
export default function UssrProfile({ settabName }) {

    const [AllCountries, setAllCountries] = useState([])
    useEffect(() => {
        getAllCountries()
    }, [])
    async function getAllCountries() {
        axios
            .get(
                `https://comms.globalxchange.io/coin/vault/countries/data/get`
            )
            .then((res) => {
                if (res.data.status) {
                    setAllCountries(res?.data?.countries)
                }
                else {
                    setAllCountries([])
                }
            });
    }
    const history = useHistory()
    const [client, setclient] = useState({
        Fname: "",
        Lname: "",
        Contact: "",
        photo: "",
        flag: "",
        State: "",
        Birthday: "",
        MaritalStatus: "",
        gender: "",
        Pincode: "",
        children: "",
        address: "",
        city: "",
        Pannumber: "",
        country: "",

        brokerName: "",




    })
    console.log("client", client)

    const submitfuction = () => {

        axios
            .post(
                "https://api.retired.app/retired/user/add/userprofile",
                {
                    "email": localStorage.getItem("LoginAccount"),
                    "token": localStorage.getItem("AccessToken"),
                    "firstName": client?.Fname,
                    "lastName": client?.Lname,
                    "birthday": client?.Birthday,
                    "profilePic": client?.photo,
                    "countryName": client?.country,
                    "countryFlag": client?.flag,
                    "address": client?.address,
                    "state": client?.State,
                    "city": client?.city,
                    "pincode": client?.Pincode,
                    "gender": client?.gender,
                    "martialStatus": client?.MaritalStatus,
                    "children": client?.children,
                    "userType": "advisor",
                    "phoneNumber": client?.Contact,
                    "brokerAgeName": client?.brokerName,
                    "PANnumber": client?.Pannumber
                }
            )
            .then(({ data }) => {
                if (data.status) {
                    // history.pushState('/advisor-profile')
                    settabName("Advisor Profile")
                } else {

                }
            });




    }
    return (
      <div className="editing-page">
        <div className="editingSectionTop">
          <div className="textSectionTop">
            <div className="textheader">Basic Information</div>
            <div
              style={{
                marginTop: "3.5rem",
              }}
            >
              <NewEachCustomImageUploadcustom
                title="Upload"
                name="photo"
                placeholder="Profile picture "
                stateValue={client}
                setState={setclient}
                topTitle={false}
                btnText="Upload Profile"
              />
            </div>
          </div>
          <div className="eddintright">
            <div className="Innerinnputsection">
              <div className="subdivision">
                <NewUIMyEachInput
                  title="First name"
                  placeholder="First Name"
                  // stateValue={client?.name}
                  name="Fname"
                  stateValue={client}
                  setState={setclient}
                  topTitle="true"
                />
              </div>
              <div className="subdivision">
                <NewUIMyEachInput
                  title="Last Name"
                  placeholder="Last Name"
                  // stateValue={client?.name}
                  name="Lname"
                  stateValue={client}
                  setState={setclient}
                  topTitle="true"
                />
              </div>
            </div>
            <div className="Innerinnputsection">
              <div className="subdivision">
                <NewUIMyEachInput
                  title="Phone number"
                  placeholder="Phone number"
                  // stateValue={client?.name}
                  name="Contact"
                  stateValue={client}
                  setState={setclient}
                  topTitle="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="editingSectionTop">
          <div className="textSectionTop">
            <div className="textheader">Personal Information</div>
          </div>
          <div className="eddintright">
            <div className="Innerinnputsection">
              <div className="subdivision">
                <NewDateAndPicker
                  title="Birthday"
                  placeholder="DD/MM/YYYY"
                  stateValue={client}
                  setState={setclient}
                  name="Birthday"
                  topTitle="true"
                />
              </div>
              <div className="subdivision">
                <NewEachCustomDropdown
                  dropdown={["Married", "Single"]}
                  name="MaritalStatus"
                  title="Marital Status"
                  stateValue={client}
                  setState={setclient}
                  topTitle="true"
                  type="single"
                />
              </div>
            </div>
            <div className="Innerinnputsection">
              <div className="subdivision">
                <NewUIMyEachInput
                  title="Children"
                  placeholder="Number of Children"
                  // stateValue={client?.name}
                  name="children"
                  stateValue={client}
                  setState={setclient}
                  topTitle="true"
                />
              </div>

              <div className="subdivision">
                <NewEachCustomDropdown
                  dropdown={["Male", "Female"]}
                  name="gender"
                  title="Gender"
                  placeholder="Select Gender"
                  Select
                  Gender
                  stateValue={client}
                  setState={setclient}
                  topTitle="true"
                  type="single"
                />
              </div>
            </div>
            <div className="Innerinnputsection">
              <div className="subdivision">
                <NewUIMyEachInput
                  title="Pan number"
                  placeholder="Enter Pan number"
                  // stateValue={client?.name}
                  name="Pannumber"
                  stateValue={client}
                  setState={setclient}
                  topTitle="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="editingSectionTop">
          <div className="textSectionTop">
            <div className="textheader">Address information</div>
          </div>
          <div className="eddintright">
            <div className="Innerinnputsection">
              <div className="subdivision">
                <NewUIMyEachInput
                  title="Address"
                  placeholder="Enter Address"
                  // stateValue={client?.name}
                  name="address"
                  stateValue={client}
                  setState={setclient}
                  topTitle="true"
                />
              </div>
              <div className="subdivision">
                <NewUIMyEachInput
                  title="City"
                  placeholder="Enter City"
                  // stateValue={client?.name}
                  name="city"
                  stateValue={client}
                  setState={setclient}
                  topTitle="true"
                />
              </div>
            </div>
            <div className="Innerinnputsection">
              <div className="subdivision">
                <NewUIMyEachInput
                  title="State"
                  placeholder="Enter State"
                  // stateValue={client?.name}
                  name="State"
                  stateValue={client}
                  setState={setclient}
                  topTitle="true"
                />
              </div>

              <div className="subdivision">
                <NewUIMyEachInput
                  title="Pincode"
                  placeholder="Enter Pincode"
                  // stateValue={client?.name}
                  name="Pincode"
                  stateValue={client}
                  setState={setclient}
                  topTitle="true"
                />
              </div>
            </div>

            <div className="Innerinnputsection">
              <div className="subdivision">
                <NewEachCustomDropdown
                  dropdown={AllCountries}
                  name="country"
                  title="Select Country"
                  stateValue={client}
                  setState={setclient}
                  topTitle="true"
                  type="obj"
                />
              </div>

              <div className="subdivision"></div>
            </div>
          </div>
        </div>

        <div className="editingSectionTop">
          <div className="textSectionTop">
            <div className="textheader">Brokerage information</div>
          </div>
          <div className="eddintright">
            <div className="Innerinnputsection">
              <div className="subdivision">
                <NewEachCustomDropdown
                  dropdown={["Real Asset"]}
                  name="brokerName"
                  title="Brokerage"
                  placeholder="Select"
                  Select
                  Gender
                  stateValue={client}
                  setState={setclient}
                  topTitle="true"
                  type="single"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="labelsubmit">
          <div className="submitlabel" onClick={submitfuction}>
            Submit
          </div>
        </div>

        {/* <div className="Innerinnputsection">

                <div className='subdivision'>

                    <NewDateAndPicker
                        title="Date of birth"
                        placeholder="DD/MM/YYYY"
                        stateValue={client}
                        setState={setclient}
                        name="Birthday"
                        topTitle="true"
                    />
                </div>
                <div className='subdivision'>

                    <NewEachCustomDropdown
                        dropdown={[
                            "Male",
                            "Female",

                        ]}
                        name="gender"
                        title="Gender"
                        placeholder="Select Gender"
                        Select Gender
                        stateValue={client}
                        setState={setclient}
                        topTitle="true"
                        type="single"
                    />


                </div>
                <div className='subdivision'>
                    <NewUIMyEachInput
                        title="Height (inches)"
                        // stateValue={client?.name}
                        placeholder="Enter Height"
                        name="height"
                        stateValue={client}
                        setState={setclient}
                        topTitle="true"
                    />
                </div>

                <div className='subdivision'>
                    <NewUIMyEachInput
                        title="Weight (Kgs)"
                        // stateValue={client?.name}
                        name="weight"
                        placeholder="Enter Weight in Kgs"
                        stateValue={client}
                        setState={setclient}
                        topTitle="true"
                    />
                </div>
            </div>


            <div className="Innerinnputsection">
                <div className='subdivision'>

              


                </div>
            </div> */}
      </div>
    );
}
