import { FiSearch } from "react-icons/fi";
import React, { useState, useContext, useEffect } from "react";
import ReactQuill from "react-quill";
import AdvisorSidebar from "../../components/AdvisorSidebar/AdvisorSidebar";
import "./Resulit.scss";

import AmazonImg from "../../assets/Images/AdvisorList/amazon.png";
import DocImg from "../../assets/Images/AdvisorList/doc.png";
import FilterImg from "../../assets/Images/AdvisorList/settings.png";

import axios from "axios";
import BackArrowImg from "../../assets/Images/AdvisorList/back_arrow.png";
import { useHistory } from "react-router-dom";
import { Globalcontext } from "../../contexts/GlobalContext";
import chaticon from "../../assets/chaticon.svg";

const reports = [
  {
    id: 1,
    title: "New report from Harvey",
    idText: "Abc-report-125898",
    image: DocImg,
  },
  {
    id: 2,
    title: "Amazon had a stock boom...",
    idText: "Abc-report-125898",
    image: AmazonImg,
  },
  {
    id: 3,
    title: "New report from Harvey",
    idText: "Abc-report-125898",
    image: DocImg,
  },
];

const ClientChat = () => {
  const [globalseacrhadviser, setglobalseacrhadviser] = useState("");
  const [message, setMessage] = useState("");
  const {
    email,
    token,
    finalDetail,
    tostShowOn,
    proposal,
    setSelectitem,
    clinetDetail,
    setclinetDetail,
    setgobalseacrhadviser,
    opentoggleclass,
    setopentoggleclass,
  } = useContext(Globalcontext);
  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };
  console.log("Message sent:", proposal);
  const handleSendMessage = () => {
    setMessage("");
  };
  const [Namelist, setNamelist] = useState("Chat");
  const [add, setadd] = useState(0);
  const history = useHistory();
  const datafunction = (e) => {
    setSelectitem(e);
    history.push(`/advisor-client/client-goal`);
  };
  const [resultdata, setresultdata] = useState({});
  // const added = resultdata?.productDetails?.map((s, key) => {
  //     return (
  //         <>
  //             {s?.fields?.reduce((accumulator, item) => accumulator + item?.totalinvestmentamount, 0)}
  //         </>

  //     )
  // }
  // )

  useEffect(() => {
    proposalResult();
  }, [finalDetail?.unique_id]);
  const proposalResult = (e) => {
    axios
      .get(
        `https://api.retired.app/retired/proposal/get?unique_id=${finalDetail?.unique_id}`
      )
      .then(({ data }) => {
        if (data.status) {
          setresultdata(data?.data?.[0]);

          //setdatalist(data?.data);
        } else {
          setresultdata({});
        }
      });
  };
  // console.log("resultdata", added)
  return (
    <div>
      <div className="advisor-chat-pagemainflex">
        <AdvisorSidebar />

        <div className="advisor-chat-advisor-rightsidedatashow">
          <div className="searchProfileContainer">
            <div className="profileTextContainer">
              <div className="topBar">
                <button className="backButton">
                  <img
                    src={BackArrowImg}
                    alt="back arrow"
                    onClick={() => history.goBack()}
                  />
                </button>
              </div>
              <span className="profileName">
                Proposal Made On{" "}
                {new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                }).format(finalDetail?.timestamp)}
              </span>
            </div>
            <span
              className="profileName"
              style={{
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              Status: {finalDetail?.proposalStatus}
            </span>
          </div>

          <div className="chatContainer">
            <div className="plabbtable-section">
              <table
                style={{
                  marginBottom: "2rem",
                  border: "1px solid #E5E5E5",
                }}
              >
                <tr>
                  <th>Product</th>

                  <th>Avg rate of return</th>
                  <th>Contribution Frequency</th>
                  <th>% of total target investment</th>
                  <th>Asset value at target date</th>
                  <th>Number Of Months</th>
                  <th>Monthly Contribution</th>
                  <th>Total Contribution</th>
                  <th>% of total contribution</th>
                  <th>Approved Status</th>
                </tr>

                {resultdata?.productDetails?.map((item, key) => {
                  return (
                    <tr>
                      <td>
                        <div
                          className="dropdown-planb"
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {item?.productName}
                        </div>
                      </td>
                      <td>
                        {item?.fields?.map((each, j) => {
                          if (each?.expectedROI)
                            return (
                              <div className="text-handle">
                                {each?.expectedROI}
                              </div>
                            );
                        })}
                      </td>

                      <td>
                        {item?.fields?.map((each, j) => {
                          if (each?.contributionFrequency)
                            return (
                              <div className="text-handle">
                                {each?.contributionFrequency}
                              </div>
                            );
                        })}
                      </td>

                      <td>
                        {item?.fields?.map((each, j) => {
                          if (each?.contributionpercentage)
                            return (
                              <div className="text-handle">
                                {each?.contributionpercentage}
                              </div>
                            );
                        })}
                      </td>

                      <td>
                        {item?.fields?.map((each, j) => {
                          if (each?.expectedbalance)
                            return (
                              <div className="text-handle">
                                {each?.expectedbalance}
                              </div>
                            );
                        })}
                      </td>

                      <td>
                        {item?.fields?.map((each, j) => {
                          if (each?.termlength)
                            return (
                              <div className="text-handle">
                                {each?.termlength}
                              </div>
                            );
                        })}
                      </td>

                      <td>
                        {item?.fields?.map((each, j) => {
                          if (each?.monthlycontribution)
                            return (
                              <div className="text-handle">
                                {each?.monthlycontribution}
                              </div>
                            );
                        })}
                      </td>

                      <td>
                        {item?.fields?.map((each, j) => {
                          if (each?.totalinvestmentamount)
                            return (
                              <div className="text-handle">
                                {Number(each?.totalinvestmentamount)?.toFixed(
                                  2
                                )}
                              </div>
                            );
                        })}
                      </td>

                      <td>
                        {item?.fields?.map((each, j) => {
                          if (each?.allocationpercentage)
                            return (
                              <div className="text-handle">
                                {Number(each?.allocationpercentage)?.toFixed(2)}
                              </div>
                            );
                        })}
                      </td>

                      <td>
                        <div
                          className="underReview"
                          style={{
                            background:
                              resultdata?.proposalStatus == "submitted"
                                ? "#FF9100"
                                : resultdata?.proposalStatus == "approved"
                                ? "#1BA27A"
                                : resultdata?.proposalStatus == "rejected"
                                ? "#EA0F0F"
                                : resultdata?.proposalStatus ==
                                  "request for resubmission"
                                ? "#58B135"
                                : "#FF9100",
                          }}
                        >
                          {resultdata?.proposalStatus == "submitted"
                            ? "Under review"
                            : resultdata?.proposalStatus == "approved"
                            ? "Approved"
                            : resultdata?.proposalStatus == "rejected"
                            ? "Rejected"
                            : resultdata?.proposalStatus ==
                              "request for resubmission"
                            ? "Request For Resubmission"
                            : ""}
                          {/* */}
                        </div>
                      </td>
                      {/* <td

>
 
    {
        item?.fields?.map((each, j) => {
            if (each?.totalinvestmentamount)
              
            return (


                <div className='text-handle'>
                    {Number(each?.totalinvestmentamount)?.toFixed(2)}
                </div>
            )
        })
    }
</td> */}
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div
                      className="dropdown-planb"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      Subtotal
                    </div>
                  </td>

                  <td></td>
                  <td></td>
                  <td>
                    {/* <div className='dropdown-planb'
                                            style={{
                                                justifyContent: "center"
                                            }}

                                        >


                                            {added}

                                        </div> */}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientChat;
