import React from "react";
import "./aboutus.css";
import Navbar from "../../components/Navbar/Navbar/Navbar";
import mainimage from "../../assets/Images/About/mainimage.jpg";
import { retiredapp } from "../../assets/Func/navigations";

const Aboutus = () => {
  return (
    <section>
      <Navbar />
      <div className="firstsection-aboutus">
        {/* <div>
          <img src={mainimage} alt="mainimage" className="about-mainimg"/>
        </div> */}
        <div>
          <div>Where Technology & Expertise Collide</div>
          <p>
            <span style={{ color: "#58B135" }}>Retired App </span>is combining
            innovative technology and decades of lending experience to bring a
            simple and effective alternative investment option to individual
            investors.
          </p>
          <p>
            The <span style={{ color: "#58B135" }}>Retired App </span>
            alternative investment method creates an opportunity for
            institutional-quality fixed income returns for individuals. With low
            minimum investments,{" "}
            <span style={{ color: "#58B135" }}>Retired App </span> is
            democratizing access to investments in small business debt.
          </p>
        </div>
      </div>
      <div className="secondsection-aboutus">
        <div className="secondsection-content">
          <div>Efficient Alternative Investment Solutions</div>
          <p>
            Investing in debt with EqualSeat provides a dependable fixed income
            opportunity for individuals
          </p>
          <p>
            <span style={{ color: "#58B135" }}>Retired App </span> is paving the
            way for change with transparency, predictability and simplicity in a
            digital platform of quality debt instruments. A quick application
            and verification process allows individuals and accredited investors
            to select their investment options right away and start earning.
          </p>
          <p>
            <span style={{ color: "#58B135" }}>Retired App </span> provides a
            fully transparent platform so you can understand your unique
            portfolio and its risks and returns. Clear and accurate reports are
            available at all times so you can make informed decisions.
          </p>
          <div className="twelfth-last-btn" onClick={retiredapp}>
            Get Started
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aboutus;
