import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import UserProfileView from "./SubTabsView/UserProfileView";
import AdvisorProfileView from "./SubTabsView/AdvisorProfileView";
import axios from "axios";

export default function ViewProfile() {
  const history = useHistory();
  const { profileType } = useParams();
  const [tabName, settabName] = useState(""); //default "User Profile"
  const [client, setclient] = useState("");

  //functions

  async function getAllProfileInformation() {
    try {
      let response = await axios.get(
        `https://comms.globalxchange.io/retired/user/get/userprofiles?email=${localStorage.getItem(
          "LoginAccount"
        )}`
      );

      if (response?.data?.status && response?.data?.data?.length > 0) {
        setclient(response?.data?.data[0]);
        console.log(response?.data?.data, "Get profile information response");
      }
    } catch (error) {
      console.log(error?.message, "Get profile information error");
    }
  }

  //renderings

  useEffect(() => {
    if (localStorage.getItem("LoginAccount")) {
      getAllProfileInformation();
    }
  }, []);

  useEffect(() => {
    if (profileType === "userprofile") {
      settabName("User Profile");
    } else if (profileType === "advisorprofile") {
      settabName("Advisor Profile");
    }
  }, [profileType]);

  return (
    <div className="profile-rightsidedatashow">
      <div className="tabs">
        <div
          className="name"
          style={{
            color: tabName == "User Profile" && "#58B135",
          }}
          onClick={() => {
            history.push("/profile/userprofile");
          }}
        >
          User Profile
          {tabName == "User Profile" && <div className="line"></div>}
        </div>
        <div
          className="name"
          style={{
            color: tabName == "Advisor Profile" && "#58B135",
          }}
          onClick={() => {
            history.push("/profile/advisorprofile");
          }}
        >
          Advisor Profile
          {tabName == "Advisor Profile" && <div className="line"></div>}
        </div>
      </div>
      {tabName == "User Profile" ? (
        <UserProfileView client={client} setclient={setclient} />
      ) : tabName == "Advisor Profile" ? (
        <AdvisorProfileView client={client} setclient={setclient} />
      ) : (
        ""
      )}
    </div>
  );
}
