import React, { useContext, useEffect, useState } from "react";
import "./calculatorsection.css";
import secondsectionside from "../../../../assets/Images/Home/secondsectionside.jpg";

import { AiOutlineClose } from "react-icons/ai";

//calculator section icons
import { IoAirplaneSharp, IoHome, IoCarSportSharp } from "react-icons/io";
import {
  FaBriefcaseMedical,
  FaHatCowboy,
  FaPaintRoller,
  FaWallet,
} from "react-icons/fa";
import { MdChildFriendly } from "react-icons/md";
import {
  calculatorcategories,
  calculatorLeftCard,
} from "../../../../assets/data/calculatorcategories";
import { Globalcontext } from "../../../../contexts/GlobalContext";
import { Link } from "react-router-dom";

const Calculatorsection = () => {
  const { allgoals } = useContext(Globalcontext);
  const [selectedleft, setselectedleft] = useState({
    number: "01",
    content:
      "In Order For Us To Create Your R-PlanB, We Need To Know Where You Stand Today",
  });
  const [changedpage, setChangedpage] = useState();

  function selectNav(firsttime) {
    if (
      firsttime !== "firsttime" &&
      firsttime !== "secondtime" &&
      firsttime !== "thirdtime" &&
      firsttime !== "fourthtime"
    ) {
      let textt = firsttime.target.innerText;
      if (textt === "01") {
        selectNav("firsttime");
      } else if (textt === "02") {
        selectNav("secondtime");
      } else if (textt === "03") {
        selectNav("thirdtime");
      } else if (textt === "04") {
        selectNav("fourthtime");
      }
    }

    let marker = document.querySelector("#marker");
    let item = document.querySelectorAll(
      ".calculator-section-left-markers div"
    );
    function indicator(e) {
      marker.style.left = e.offsetLeft + "px";
      marker.style.width = e.offsetWidth + "px";
    }

    let num = +selectedleft.number.split("")[1];

    if (firsttime === "firsttime") {
      indicator(item[0]);
    } else if (firsttime === "secondtime") {
      indicator(item[1]);
    } else if (firsttime === "thirdtime") {
      indicator(item[2]);
    } else if (firsttime === "fourthtime") {
      indicator(item[3]);
    } else if (firsttime == "Next") {
      if (num < 4) {
        indicator(item[num]);
      }
    } else if (firsttime == "Previous") {
      if (num > 0) {
        indicator(item[num - 2]);
      }
    }

    item.forEach((link) => {
      link.addEventListener("click", (e) => {
        indicator(e.target);
        setContentLeftCalculator(e.target.innerText);
      });
    });
  }

  function setContentLeftCalculator(selectednum) {
    calculatorLeftCard.forEach((data) => {
      if (data.number === selectednum) {
        setselectedleft(data);
      }
    });
  }

  useEffect(() => {
    let currenturl = window.location.pathname.split("/")[2];

    if (currenturl === "step1") {
      selectNav("firsttime");
    } else if (currenturl === "step2") {
      selectNav("secondtime");
    } else if (currenturl === "step3") {
      selectNav("thirdtime");
    } else if (currenturl === "step4") {
      selectNav("fourthtime");
    }
  }, [changedpage]);

  function prevNextBtn(event) {
    let selected = event.target.innerText;
    let num = +selectedleft.number.split("")[1];

    let currentpath = window.location.pathname.split("/")[2];
    currentpath = +currentpath.split("")[4];
    setContentLeftCalculator("0" + currentpath);
    setChangedpage(currentpath);

    // console.log(num, selected, "checking");

    // if (selected == "Next") {
    //   if (currentpath < 4) {
    //     console.log(currentpath, "numberfront");
    //     setContentLeftCalculator("0" + (currentpath + 1));
    //     selectNav("Next");
    //   }
    // } else if (selected == "Previous") {
    //   if (num > 1) {
    //     console.log(currentpath, "numberback");
    //     setContentLeftCalculator("0" + (currentpath - 1));
    //     selectNav("Previous");
    //   }
    // }
    // if (selected == "Next") {
    //   if (currentpath < 4) {
    //     window.location.href = "/rplanb/step" + (currentpath + 1);
    //   }
    // } else if (selected == "Previous") {
    //   if (currentpath > 0) {
    //     window.location.href = "/rplanb/step" + (currentpath - 1);
    //   }
    // }
  }

  return (
    <section className="calculator-section">
      <div className="calculator-section-left">
        <div>
          <img src={secondsectionside} alt="secondsectionside" />
        </div>
        <div>
          <div
            style={{
              fontSize: "2.5rem",
              color: "rgb(68, 194, 244)",
              fontWeight: 800,
            }}
          >
            {selectedleft.number}
          </div>
          <div>{selectedleft.content}</div>
        </div>
        <div className="calculator-section-left-markers">
          <span id="marker"></span>
          <span id="markerunder"></span>
          <div onClick={selectNav}>
            <Link to="/rplanb/step1">01</Link>
          </div>
          <div onClick={selectNav}>
            <Link to="/rplanb/step2">02</Link>
          </div>
          <div onClick={selectNav}>
            <Link to="/rplanb/step3">03</Link>
          </div>
          <div onClick={selectNav}>
            <Link to="/rplanb/step4">04</Link>
          </div>
        </div>
      </div>
      <div className="calculator-section-right">
        <div className="calculator-right-heading">
          <div>Add your dream retirement goals</div>
          <div>What dream retirement goal would you like to include?</div>
        </div>
        <div className="calculator-right-categories">
          {calculatorcategories.map((eachcategories) => {
            return <Rightcategories eachcategories={eachcategories} />;
          })}
        </div>
        <div className="calculator-right-second-heading">
          <div>Your dream retirement goals</div>
          <div>Set your dream retirement goals in today's dollar</div>
          <div>{allgoals.length} goals</div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "20px",
            flexDirection: "column",
          }}
        >
          {allgoals.map((eachgoal, i) => {
            return <SelectedCardcalculator eachgoal={eachgoal} index={i} />;
          })}
        </div>
        <div className="calculator-right-btn">
          <div onClick={prevNextBtn}>
            <Link
              to={
                +window.location.pathname.split("/")[2].split("")[4] > 1
                  ? "/rplanb/step" +
                    (+window.location.pathname.split("/")[2].split("")[4] - 1)
                  : "/rplanb/step1"
              }
            >
              Previous
            </Link>
          </div>
          <div
            style={{ background: "#58B135", color: "white" }}
            onClick={prevNextBtn}
          >
            <Link
              to={
                +window.location.pathname.split("/")[2].split("")[4] < 4
                  ? "/rplanb/step" +
                    (+window.location.pathname.split("/")[2].split("")[4] + 1)
                  : "/rplanb/step4"
              }
            >
              Next
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calculatorsection;

const Rightcategories = ({ eachcategories }) => {
  const { allgoals, setallgoals } = useContext(Globalcontext);

  function setGoals(newgoal) {
    setallgoals([...allgoals, { goal: newgoal }]);
  }

  return (
    <div
      className="right-categories"
      onClick={(event) =>
        setGoals(event.target.innerText || event.target.childNodes[0].innerText)
      }
    >
      {/* <div>{<eachcategories.icon />}</div> */}
      <div style={{ fontWeight: 400, color: "#110F0D", fontSize: "1.2rem" }}>
        {eachcategories.name}
      </div>
    </div>
  );
};

const SelectedCardcalculator = ({ eachgoal, index }) => {
  const { allgoals, setallgoals } = useContext(Globalcontext);

  function removeItem(goal) {
    const result = allgoals.filter((eachgoal) => eachgoal.goal !== goal);
    setallgoals(result);
  }
  return (
    <div className="selected-card">
      <div>
        <span>{"0" + (index + 1)}</span>
        <span>{eachgoal.goal}</span>
      </div>
      <div
        className="selected-card-arrow"
        onClick={() => removeItem(eachgoal.goal)}
      >
        <AiOutlineClose />
      </div>
      <div>
        <div>
          <label htmlFor="cost">Cost</label>
          <select name="cars" id="cost" className="selected-card-options">
            <option value="10000">10,000</option>
            <option value="20000">20,000</option>
            <option value="30000">30,000</option>
            <option value="40000">40,000</option>
          </select>
        </div>
        <div>
          <label htmlFor="years">Frequency</label>
          <select name="cars" id="years" className="selected-card-options">
            <option value="2">Every 2 years</option>
            <option value="4">Every 4 years</option>
            <option value="6">Every 6 years</option>
          </select>
        </div>
        <div>
          <label htmlFor="cost">Age period</label>
          <input type="range" id="age" min="0" max="100" />
        </div>
      </div>
    </div>
  );
};
