import React from "react";
import "./fourthsection.css";

const Fourthsection = () => {
  return (
    <section className="fourth-section">
      <div>
        <div>
          <span className="blueText">Retired app</span>
          <span> is Bridging the Gap to</span>
          <span className="blueText"> Investor-Corporate-DeFi</span>
          <span> Fixed Income Investments</span>
        </div>
        <div>
          <div>FD returns are low. Stocks are tense. Crypto is volatile.</div>
          <div style={{ color: "#27304F" }}>Where can I invest?</div>
        </div>
      </div>
    </section>
  );
};

export default Fourthsection;
