import React, { useContext } from "react";
import "./Carer.scss";
import "./topcard.scss";
import CloseIcon from "../../assets/Image/closeicon.svg";
import IndianOTCIcon from "../../assets/Image/indianotcicon.svg";
import TaxChainIcon from "../../assets/Image/taxchainicon.svg";
import IndianInvestorIcon from "../../assets/Image/indianinvestoricon.svg";
import IndiaCoinsIcon from "../../assets/Image/indiacoinsicon.svg";
import IndianMarketIcon from "../../assets/Image/metaverseicon.svg";
import BhararTrustIcon from "../../assets/Image/bharattrusticon.svg";
import InstaIcon from "../../assets/Image/instasvg.svg";
import DiscordIcon from "../../assets/Image/discordsvg.svg";
import YoutubeIcon from "../../assets/Image/youtubesvg.svg";
import EmailIcon from "../../assets/Image/mailsvg.svg";
import { Globalcontext } from "../../contexts/GlobalContext";

function TopCard() {
  const {
    opencard,
    pagemask,
    therightcard,
    closedisp,
    closerightdisp,
    setopencard,
    setpagemask,
    settherightcard,
    setclosedisp,
    setcloserightdisp,
    setSideBarStatus,
    sideBarStatus,
  } = useContext(Globalcontext);

  let carddata = [
    {
      image: IndianOTCIcon,
      title: "IndianOTC",
      desc: "Digital Asset Trading Hub",
    },
    {
      image: TaxChainIcon,
      title: "TaxChains",
      desc: "Crypto Tax Software",
    },
    {
      image: IndianInvestorIcon,
      title: "IndianInvestor",
      desc: "Wealth Management System",
    },
    {
      image: IndiaCoinsIcon,
      title: "IndiaCoins",
      desc: "India`s NFT Marketplace",
    },
    {
      image: IndianMarketIcon,
      title: "IndianMarket",
      desc: "Financial Media & News",
    },
    {
      image: BhararTrustIcon,
      title: "BharatTrust",
      desc: "Social Investing Platform",
    },
  ];

  let rightdata = [
    {
      image: InstaIcon,
      title: "Instagram",
      desc: "@retired.app",
    },
    {
      image: DiscordIcon,
      title: "Discord",
      desc: "Join Server",
    },
    {
      image: YoutubeIcon,
      title: "Youtube",
      desc: "Go To Channel",
    },
    {
      image: EmailIcon,
      title: "Email",
      desc: "support@inr.group",
    },
  ];
  const closemenu = (e) => {
    setopencard("translateY(-140%)");
    setpagemask("");
    setclosedisp("none");
  };

  const rightclose = (e) => {
    settherightcard("translateY(-140%)");
    setpagemask("");
    setcloserightdisp("none");
  };
  const opensite = (e) => {
    switch (e.currentTarget.id) {
      case "IndianOTC":
        window.open("https://indianotc.com", "_blank");
        break;
      case "TaxChains":
        window.open("https://taxchains.com", "_blank");
        break;
      case "IndianInvestor":
        window.open("https://indianinvestor.com", "_blank");
        break;
      case "IndiaCoins":
        window.open("https://indiacoins.com", "_blank");
        break;
      case "IndianMarket":
        window.open("https://indian.market", "_blank");
        break;
      case "BharatTrust":
        window.open("https://bharattrust.com", "_blank");
        break;
      default:
        break;
    }
  };

  function closeSidebar(event) {
    settherightcard("translateX(-140%)");
    setopencard("translateX(-140%)");
    setpagemask("");
    if (sideBarStatus) {
      setSideBarStatus(!sideBarStatus);
    }
  }

  return (
    <>
      <div
        className={pagemask}
        id="wrapperref"
        onClick={closeSidebar}
        // style={{ display: sideBarStatus ? "block" : "none" }}
      ></div>
      <div
        className="sidemenu"
        style={{ transform: opencard }}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className="side-text">
          <img
            src={CloseIcon}
            alt="close-icon"
            className="close-img"
            onClick={closemenu}
            style={{ display: closedisp }}
          />
          <p className="card-title">One Account. Mutiple Advantages</p>
          <p className="card-desc">
            With An IndianOTC Account You Also Get Access To The INR Group Apps
          </p>
          <br />
          {carddata.map((e, i) => {
            return (
              <div
                className="card-data"
                onClick={opensite}
                id={e.title}
                key={e.title + i}
              >
                <img src={e.image} alt="indian-otc" className="company-logo" />
                <div className="card-text">
                  <p className="comp-title">{e.title}</p>
                  <p className="comp-desc">{e.desc}</p>
                </div>
                <br />
              </div>
            );
          })}
        </div>
      </div>
      {/* rightmenu */}
      <div
        className="rightmenu"
        style={{ transform: therightcard }}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <img
          src={CloseIcon}
          alt="close-icon"
          className="close-img-right"
          onClick={rightclose}
          style={{ display: closerightdisp }}
        />
        <div className="side-text">
          <p className="right-card-title">Connect With Us</p>
          <p className="right-card-desc">We Would Love To Hear From You</p>
          <br />
          {rightdata.map((e, i) => {
            return (
              <div className="card-data" key={e.title + i}>
                <img src={e.image} alt="indian-otc" className="company-logo" />
                <div className="card-text">
                  <p className="comp-title">{e.title}</p>
                  <p className="comp-desc">{e.desc}</p>
                </div>
                <br />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default TopCard;
