import React, { useEffect, useRef, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import './firstsection.scss'
import Slider from "react-slick";
import img1 from "../../../../assets/img1.svg"
import andriodlogo from "../../../../assets/andriodlogo.svg"
import apple from "../../../../assets/applelog.svg"
import a1 from "../../../../assets/a1.svg"
import a2 from "../../../../assets/a2.svg"
import a3 from "../../../../assets/a3.svg"
import a4 from "../../../../assets/a4.svg"
import a5 from "../../../../assets/a5.svg"
import a6 from "../../../../assets/a6.svg"
import { useHistory } from "react-router-dom";
import logolanding from "../../../../assets/landinglogo.svg"
export default function Planb() {
  const history = useHistory()
  const [anser, setanser] = useState("0");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    console.log("dropdownOpen", dropdownOpen);
    setDropdownOpen(!dropdownOpen);
  }

  const handleNavigation = (route) => {
    history.push(route);
    setDropdownOpen(false);
  }

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      console.log("Clicked outside");

      setDropdownOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [])


  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "160px",
    slidesToShow: 3,
    speed: 500,
    focusOnSelect: true, autoplaySpeed: 2000,
    dots: true,
    autoplay: true,
  };
  const [donutchart, setdonutchart] = useState({


    series: [44, 55, 41, 17, 15],
    options: {
      chart: {
        type: 'donut',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },


  }

  )
  const [chartdat, setchartdata] = useState(
    {

      series: [{
        name: 'Sales',
        data: [10, 5, 29, 7, 5, 13, 9]
      }],
      options: {

        tooltip: {

          fixed: {
            enabled: true
          },
          enabled: true, marker: {
            show: true,
          },
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            let add = 100 - data

            setanser(data)

            return (
              `<div class="donutchartl" >
                        
                             
                            </div>`
            )


          }
        },

        chart: {
          height: 160,
          type: 'line',

          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#000000', '#545454'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          size: 2
        },
        title: {
          text: '',
          align: 'left',
          labels: {
            show: false
          },
        },
        // grid: {
        //     show: false
        // },
        markers: {
          size: 5
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
          title: {
            text: ''
          },


        },
        points: [{
          x: "Feb",
          y: 5,
          marker: {
            size: 8,
            fillColor: '#fff',
            strokeColor: 'red',
            radius: 2,
            cssClass: 'apexcharts-custom-class'
          },
          label: {
            borderColor: '#FF4560',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '#FF4560',
            },

            text: 'Point Annotation',
          }
        }],
        yaxis: {
          show: false,
          labels: {
            show: false
          },
          title: {
            text: ''
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          min: 5,
          max: 40
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -250,
          offsetX: -5
        }






      },


    }

  )

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
  };
  return (
    <>
      <div className="lanbding-nav">
        <div className="nav-tabs-text">
          <img src={logolanding} />
          <div className="text-nav">
            <div>Features</div>
            <div>About us</div>
            <div>FAQs</div>
            <div>Download</div>
          </div>
          {/* <label
            onClick={() =>histroy.push('/login')}
          >Login

            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
              <path d="M9.75649 0.983643L5.72715 5.01298L1.69782 0.983643L0.454285 2.22713L5.72715 7.5L11 2.22713L9.75649 0.983643Z" fill="white" />
            </svg>
          </label> */}
          <div className="dropdown-container" ref={dropdownRef}>
            <label onClick={handleDropdownToggle}>
              Login
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="8"
                viewBox="0 0 11 8"
                fill="none"
              >
                <path
                  d="M9.75649 0.983643L5.72715 5.01298L1.69782 0.983643L0.454285 2.22713L5.72715 7.5L11 2.22713L9.75649 0.983643Z"
                  fill="white"
                />
              </svg>
            </label>
            {dropdownOpen && (
              <div className="dropdown-menu">
                <div onClick={() => handleNavigation("/client/login")}>
                  Client
                </div>
                <div onClick={() => handleNavigation("/advisor/login")}>
                  Advisor
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="planb-dashboard">
        <div className="pageOne">
          <div className="leftsidetext">
            <h1>Plan Your Tomorrow </h1>
            <h1>
              with <span>Retired</span>
            </h1>
            <h2>Your path to freedom</h2>
            <p>
              With this user-friendly platform, you can define your aspirations,
              from retirement security to educational funding, and connect with
              qualified advisors who craft personalized investment strategies
              aligned with your unique needs.
            </p>

            <div className="tableinside">
              <div className="cointingtable">
                <div className="value ">30K</div>
                <div className="title">Clients & Projects</div>
              </div>
              <div className="cointingtable">
                <div className="value ">1600+</div>
                <div className="title">Advisors</div>
              </div>
              <div className="cointingtable">
                <div className="value ">90%</div>
                <div className="title">Clients returned</div>
              </div>
            </div>
          </div>
          <div className="rightside-chartsection">
            <div className="rightsidechatside">
              <div className="bottom-message">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1661_468)">
                    <path
                      d="M27.8292 19.7992L23.1771 27.3374L21.2598 26.1521L25.912 18.6139L27.8292 19.7992ZM9.55888 2.31831L8.37807 0.394287L0.782333 5.04308L1.95863 6.96598L9.55888 2.31831ZM23.5481 6.22727L25.6909 4.08445L24.0985 2.48748L21.9658 4.62016C20.4259 3.39903 18.543 2.68954 16.5802 2.59086C14.6174 2.49219 12.6728 3.00925 11.0182 4.06979L3.39206 8.29791C2.98622 8.56637 2.64515 8.92171 2.39355 9.3382C2.14195 9.7547 1.98611 10.2219 1.93731 10.7061C1.88852 11.1902 1.948 11.6792 2.11144 12.1375C2.27487 12.5958 2.53818 13.0121 2.8823 13.3561L14.8189 25.2972C15.1217 25.6032 15.4821 25.8462 15.8793 26.0121C16.2766 26.178 16.7027 26.2635 17.1332 26.2638C17.2745 26.2635 17.4158 26.2549 17.5561 26.2378C18.0513 26.1775 18.5258 26.0038 18.943 25.7304C19.3602 25.457 19.7088 25.0911 19.9617 24.6612L24.3917 16.7824C25.3115 15.137 25.7223 13.2557 25.5721 11.3768C25.422 9.49778 24.7176 7.70563 23.5481 6.22727ZM4.1725 20.9439C4.16588 21.868 4.39965 22.7779 4.85088 23.5843C5.3021 24.3907 5.95526 25.0659 6.74625 25.5436C7.53723 26.0213 8.43885 26.2852 9.3626 26.3092C10.2863 26.3332 11.2005 26.1166 12.0152 25.6807L4.80407 18.4628C4.39449 19.226 4.17767 20.0778 4.1725 20.9439Z"
                      fill="#30A4D2"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1661_468">
                      <rect
                        width="27.0672"
                        height="27.0672"
                        fill="white"
                        transform="translate(0.789185 0.324341)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div className="text-bottom-message">
                  <div className="text">12 messages from advisor</div>
                  <div className="date">Today, 1:25 PM</div>
                </div>
              </div>
              <div class="donutchart">
                <div class="textcenter">
                  <div class="goalreach">Goals Reached</div>
                  <div class="goaltext">{anser}</div>
                </div>
                <figure>
                  <div class="figure-content">
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 42 42"
                      class="donut"
                      aria-labelledby="beers-title beers-desc"
                      role="img"
                    >
                      <title id="beers-title">Beers in My Cellar</title>
                      <desc id="beers-desc">
                        Donut chart showing 10 total beers. Two beers are
                        Imperial India Pale Ales, four beers are Belgian
                        Quadrupels, and three are Russian Imperial Stouts. The
                        last remaining beer is unlabeled.
                      </desc>
                      <circle
                        class="donut-hole"
                        cx="21"
                        cy="21"
                        r="15.91549430918954"
                        fill="#fff"
                        role="presentation"
                      ></circle>
                      <circle
                        class="donut-ring"
                        cx="21"
                        cy="21"
                        r="15.91549430918954"
                        fill="transparent"
                        stroke="#d2d3d4"
                        stroke-width="5"
                        role="presentation"
                      ></circle>

                      <circle
                        class="donut-segment"
                        cx="21"
                        cy="21"
                        r="15.91549430918954"
                        fill="transparent"
                        stroke="#30A4D2"
                        stroke-width="5"
                        stroke-dasharray="40 50"
                        stroke-dashoffset="25"
                        aria-labelledby="donut-segment-1-title donut-segment-1-desc"
                      >
                        <title id="donut-segment-1-title">
                          Belgian Quadrupels
                        </title>
                        <desc id="donut-segment-1-desc">
                          Pink chart segment spanning 40% of the whole, which is
                          4 Belgian Quadrupels out of 10 total.
                        </desc>
                      </circle>
                    </svg>
                  </div>
                </figure>
              </div>
              <div className="text-top">
                <div className="incometext">Total Income</div>
                <div className="labeldropdown">
                  Week{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                  >
                    <path
                      d="M9.62648 0.977051L5.59715 5.00639L1.56781 0.977051L0.32428 2.22054L5.59715 7.49341L10.87 2.22054L9.62648 0.977051Z"
                      fill="#8F8F8F"
                    />
                  </svg>
                </div>
              </div>
              <div id="chart">
                <ReactApexChart
                  options={chartdat.options}
                  series={chartdat.series}
                  type="line"
                  height={150}
                />
              </div>
              <div id="html-dist"></div>
            </div>
          </div>
        </div>
        <div className="borderlinemiddle"></div>

        <div className="pagwtwo">
          <div className="cardss">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M18.6667 4.66667V4.08333C18.6667 3.00037 18.2365 1.96175 17.4707 1.19598C16.7049 0.430207 15.6663 0 14.5833 0H0V28H3.5V16.3333H11.6667V16.9167C11.6667 17.9996 12.0969 19.0382 12.8626 19.804C13.6284 20.5698 14.667 21 15.75 21H28V4.66667H18.6667ZM3.5 3.5H14.5833C14.738 3.5 14.8864 3.56146 14.9958 3.67085C15.1052 3.78025 15.1667 3.92862 15.1667 4.08333V12.8333H3.5V3.5ZM24.5 17.5H15.75C15.5953 17.5 15.4469 17.4385 15.3375 17.3291C15.2281 17.2197 15.1667 17.0714 15.1667 16.9167V16.3333H18.6667V8.16667H24.5V17.5Z"
                fill="#30A4D2"
              />
            </svg>
            <div className="title-text">Goal Setting</div>
            <div className="des">
              Define your dream vacation, child's education, or retirement home.
              We'll help you chart the course.
            </div>
          </div>

          <div className="cardss">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <g clip-path="url(#clip0_1661_383)">
                <path
                  d="M24.5 0H3.5C2.57174 0 1.6815 0.368749 1.02513 1.02513C0.368749 1.6815 0 2.57174 0 3.5L0 28H28V3.5C28 2.57174 27.6313 1.6815 26.9749 1.02513C26.3185 0.368749 25.4283 0 24.5 0V0ZM24.5 24.5H21V18.6667C21 18.0478 20.7542 17.4543 20.3166 17.0168C19.879 16.5792 19.2855 16.3333 18.6667 16.3333H9.33333C8.71449 16.3333 8.121 16.5792 7.68342 17.0168C7.24583 17.4543 7 18.0478 7 18.6667V24.5H3.5V3.5H24.5V24.5Z"
                  fill="#30A4D2"
                />
                <path
                  d="M13.9998 14.0008C16.255 14.0008 18.0831 12.1726 18.0831 9.91744C18.0831 7.66228 16.255 5.83411 13.9998 5.83411C11.7447 5.83411 9.91648 7.66228 9.91648 9.91744C9.91648 12.1726 11.7447 14.0008 13.9998 14.0008Z"
                  fill="#30A4D2"
                />
              </g>
              <defs>
                <clipPath id="clip0_1661_383">
                  <rect width="28" height="28" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="title-text">Advisor Interaction</div>
            <div className="des">
              Connect with qualified advisors who understand your needs and
              craft personalized plans.
            </div>
          </div>

          <div className="cardss">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="28"
              viewBox="0 0 24 28"
              fill="none"
            >
              <path
                d="M15.4287 13.146L17.9044 15.6205L13.7207 19.8042C13.3415 20.1834 12.8914 20.4842 12.3959 20.6894C11.9005 20.8947 11.3695 21.0003 10.8332 21.0003C10.297 21.0003 9.76594 20.8947 9.2705 20.6894C8.77506 20.4842 8.3249 20.1834 7.94572 19.8042L6.09539 17.955L8.57106 15.4793L10.4202 17.3285C10.5296 17.4379 10.678 17.4993 10.8326 17.4993C10.9873 17.4993 11.1357 17.4379 11.2451 17.3285L15.4287 13.146ZM23.6666 6.85883V28H0.333221V3.5C0.333221 2.57174 0.701971 1.6815 1.35835 1.02513C2.01473 0.368749 2.90496 0 3.83322 0L16.8077 0L23.6666 6.85883ZM20.1666 24.5V9.33333H14.3332V3.5H3.83322V24.5H20.1666Z"
                fill="#30A4D2"
              />
            </svg>
            <div className="title-text">Proposal Creation</div>
            <div className="des">
              Review tailored investment proposals designed to achieve your
              goals.
            </div>
          </div>

          <div className="cardss">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <g clip-path="url(#clip0_1661_395)">
                <path
                  d="M17.4999 18.2299C17.4999 16.9148 16.9775 15.6537 16.0477 14.7238C15.1178 13.7939 13.8566 13.2715 12.5416 13.2715C11.2266 13.2715 9.96538 13.7939 9.03551 14.7238C8.10565 15.6537 7.58325 16.9148 7.58325 18.2299V18.6674H11.0833V18.2299C11.0833 17.8431 11.2369 17.4722 11.5104 17.1987C11.7839 16.9252 12.1548 16.7715 12.5416 16.7715C12.9284 16.7715 13.2993 16.9252 13.5728 17.1987C13.8463 17.4722 13.9999 17.8431 13.9999 18.2299C13.9999 18.959 12.6093 20.002 11.4916 20.8397C9.65992 22.214 7.58325 23.7715 7.58325 26.2507C7.58325 26.7148 7.76763 27.16 8.09581 27.4881C8.424 27.8163 8.86912 28.0007 9.33325 28.0007H17.4999V24.5007H12.4833C12.8449 24.2044 13.2358 23.9174 13.5974 23.6409C15.4233 22.2665 17.4999 20.709 17.4999 18.2299Z"
                  fill="#30A4D2"
                />
                <path
                  d="M24.4998 14V19.8333H23.6248C23.2382 19.8327 22.8677 19.6789 22.5943 19.4055C22.321 19.1322 22.1671 18.7616 22.1665 18.375V14H18.6665V18.375C18.668 19.6896 19.1909 20.9498 20.1205 21.8794C21.05 22.8089 22.3103 23.3318 23.6248 23.3333H24.4998V28H27.9998V14H24.4998Z"
                  fill="#30A4D2"
                />
                <path
                  d="M23.883 4.11798C21.459 1.67978 18.2212 0.220772 14.7889 0.020014C11.3566 -0.180744 7.97081 0.890842 5.27905 3.02981C2.58729 5.16878 0.778521 8.22499 0.198716 11.6139C-0.381089 15.0028 0.308763 18.4865 2.13632 21.3986L4.69366 18.8401C3.85731 17.2412 3.44791 15.4534 3.50511 13.6499C3.56231 11.8464 4.08415 10.0881 5.02014 8.54539C5.95612 7.00267 7.27453 5.72772 8.84775 4.84395C10.421 3.96019 12.1957 3.49755 14.0002 3.50081C16.7817 3.51052 19.4464 4.62077 21.412 6.58898L18.6668 9.33415H25.5607C25.7279 9.3343 25.8934 9.30148 26.0479 9.23757C26.2024 9.17366 26.3428 9.07991 26.461 8.96168C26.5793 8.84346 26.673 8.70308 26.7369 8.54859C26.8008 8.39409 26.8336 8.22851 26.8335 8.06131V1.16748L23.883 4.11798Z"
                  fill="#30A4D2"
                />
              </g>
              <defs>
                <clipPath id="clip0_1661_395">
                  <rect width="28" height="28" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="title-text">Real-Time Updates</div>
            <div className="des">
              Stay informed on proposal status and track your progress towards
              financial milestones.
            </div>
          </div>

          <div className="cardss">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <g clip-path="url(#clip0_1661_407)">
                <path
                  d="M3.5 23.9167V0H0V23.9167C0 24.9996 0.430207 26.0382 1.19598 26.804C1.96175 27.5698 3.00037 28 4.08333 28H28V24.5H4.08333C3.92862 24.5 3.78025 24.4385 3.67085 24.3291C3.56146 24.2197 3.5 24.0714 3.5 23.9167Z"
                  fill="#30A4D2"
                />
                <path
                  d="M26.2501 5.8335H19.8334L22.6789 8.679L18.6668 12.6923L15.7501 9.77566L7.51227 18.0123L9.98793 20.488L15.7501 14.7247L18.6668 17.6413L25.1546 11.1547L28.0001 14.0002V7.5835C28.0001 7.11937 27.8157 6.67425 27.4875 6.34606C27.1593 6.01787 26.7142 5.8335 26.2501 5.8335Z"
                  fill="#30A4D2"
                />
              </g>
              <defs>
                <clipPath id="clip0_1661_407">
                  <rect width="28" height="28" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="title-text">Investment Preferences</div>
            <div className="des">
              Choose investment options that align with your risk tolerance and
              goals.
            </div>
          </div>

          <div className="cardss">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M28 22.1667V4.08333C28 3.00037 27.5698 1.96175 26.804 1.19598C26.0382 0.430207 24.9996 0 23.9167 0H4.08333C3.00037 0 1.96175 0.430207 1.19598 1.19598C0.430207 1.96175 0 3.00037 0 4.08333L0 22.1667H12.25V24.5H7V28H21V24.5H15.75V22.1667H28ZM3.5 4.08333C3.5 3.92862 3.56146 3.78025 3.67085 3.67085C3.78025 3.56146 3.92862 3.5 4.08333 3.5H23.9167C24.0714 3.5 24.2197 3.56146 24.3291 3.67085C24.4385 3.78025 24.5 3.92862 24.5 4.08333V18.6667H3.5V4.08333ZM17.15 9.33333H22.1667V12.8333H19.0167L15.5295 18.0635L12.0295 11.0635L10.85 12.8333H5.83333V9.33333H8.98333L12.4705 4.10317L15.9705 11.1032L17.15 9.33333Z"
                fill="#30A4D2"
              />
            </svg>
            <div className="title-text">Collaboration Tools</div>
            <div className="des">
              Communicate seamlessly with your advisor through direct messaging
              and proposal editing.
            </div>
          </div>
        </div>
        <div className="pagethree">
          <h1>Discover Our Mission & Vision </h1>
          <div className="threebox">
            <div
              style={{
                width: "50%",
                paddingRight: "15px",
              }}
            >
              <h1>to Your Financial Success</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam
              </p>
              <label>Know more</label>
            </div>
            <div
              className="lol"
              style={{
                width: "25%",
              }}
            >
              <div className="whatdo">What we do</div>
              <div className="whatdotext">
                We empower individuals to achieve their financial dreams through
                personalized goal-based planning and expert advisor connections.
              </div>
            </div>
            <div
              className="lol"
              style={{
                width: "25%",
                background: "#ECFAFF",
              }}
            >
              <div
                className="whatdo"
                style={{
                  color: "#02364A",
                }}
              >
                Origin idea
              </div>
              <div
                className="whatdotext"
                style={{
                  color: "#02364A",
                }}
              >
                Retired was born from the desire to make financial planning
                accessible and collaborative
              </div>
            </div>
          </div>

          <div className="threebox">
            <div
              className="lol"
              style={{
                background: "white",
                width: "25%",
              }}
            ></div>

            <div
              className="lol"
              style={{
                width: "25%",
              }}
            >
              <div className="whatdo">Mission</div>
              <div className="whatdotext">
                To bridge the gap between individuals and financial security.
              </div>
            </div>

            <div
              className="lol"
              style={{
                width: "25%",
                background: "#ECFAFF",
              }}
            >
              <div
                className="whatdo"
                style={{
                  color: "#02364A",
                }}
              >
                Vision
              </div>
              <div
                className="whatdotext"
                style={{
                  color: "#02364A",
                }}
              >
                To be the leading platform for goal-based financial planning in
                India.
              </div>
            </div>
            <div
              className="lol"
              style={{
                width: "25%",
              }}
            >
              <div className="whatdo">Objective</div>
              <div className="whatdotext">
                To democratize financial planning by making it accessible,
                affordable, and personalized for everyone.
              </div>
            </div>
          </div>
        </div>
        <div className="page-four">
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          <div className="clientName">Client Testimonials</div>

          <div className="containers">
            <link
              rel="stylesheet"
              type="text/css"
              charset="UTF-8"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />
            <style>{cssstyle}</style>

            <Slider {...settings}>
              <div className="cardimg">
                <img src={img1} />
                <div className="titel">Saurav.R</div>

                <div className="des">
                  Retired helped me clarify my financial goals and connect with
                  an advisor who created a plan I can trust
                </div>
              </div>

              <div className="cardimg">
                <img src={img1} />
                <div className="titel">Priya.D</div>

                <div className="des">
                  Retired helped me clarify my financial goals and connect with
                  an advisor who created a plan I can trust
                </div>
              </div>

              <div className="cardimg">
                <img src={img1} />
                <div className="titel">Amit.K</div>

                <div className="des">
                  Retired helped me clarify my financial goals and connect with
                  an advisor who created a plan I can trust
                </div>
              </div>

              <div className="cardimg">
                <img src={img1} />
                <div className="titel">Sau.p</div>

                <div className="des">
                  Retired helped me clarify my financial goals and connect with
                  an advisor who created a plan I can trust
                </div>
              </div>
              <div className="cardimg">
                <img src={img1} />
                <div className="titel">ram.R</div>

                <div className="des">
                  Retired helped me clarify my financial goals and connect with
                  an advisor who created a plan I can trust
                </div>
              </div>
            </Slider>
          </div>
          {/* <Slider {...settings}>
                    <div className='cardimg'>
                        <div>
                            <img src={img1} />
                            <div className='titel'>Saurav.R</div>

                            <div className='des'>Retired helped me clarify my financial goals and connect with an advisor who created a plan I can trust</div>
                        </div>
                    </div>
                    <div className='cardimg'>
                        <div>
                            <img src={img1} />
                            <div className='titel'>Saurav.R</div>

                            <div className='des'>Retired helped me clarify my financial goals and connect with an advisor who created a plan I can trust</div>
                        </div>
                    </div>
                    <div className='cardimg'>
                        <div>
                            <img src={img1} />
                            <div className='titel'>Saurav.R</div>

                            <div className='des'>Retired helped me clarify my financial goals and connect with an advisor who created a plan I can trust</div>
                        </div>
                    </div>
                    <div className='cardimg'>
                        <div>
                            <img src={img1} />
                            <div className='titel'>Saurav.R</div>

                            <div className='des'>Retired helped me clarify my financial goals and connect with an advisor who created a plan I can trust</div>
                        </div>
                    </div>
                    <div className='cardimg'>
                        <div>
                            <img src={img1} />
                            <div className='titel'>Saurav.R</div>

                            <div className='des'>Retired helped me clarify my financial goals and connect with an advisor who created a plan I can trust</div>
                        </div>
                    </div>
                    <div className='cardimg'>
                        <div>
                            <img src={img1} />
                            <div className='titel'>Saurav.R</div>

                            <div className='des'>Retired helped me clarify my financial goals and connect with an advisor who created a plan I can trust</div>
                        </div>
                    </div>
                </Slider>; */}
        </div>

        <div className="sec-five">
          <div className="title">Frequently Asked Questions</div>
          <div className="box-section">
            <div className="box">
              What types of financial goals can I set with Retired?
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M17.25 8.25001H9.74999V0.749988C9.74999 0.335777 9.41421 0 9 0C8.58579 0 8.25001 0.335777 8.25001 0.749988V8.24998H0.749988C0.335777 8.25001 0 8.58579 0 9C0 9.41421 0.335777 9.74999 0.749988 9.74999H8.24998V17.25C8.24998 17.6642 8.58575 18 8.99996 18C9.41417 18 9.74995 17.6642 9.74995 17.25V9.74999H17.2499C17.6642 9.74999 17.9999 9.41421 17.9999 9C18 8.58579 17.6642 8.25001 17.25 8.25001Z"
                  fill="#02364A"
                />
              </svg>
            </div>
            <div className="box">
              How do I connect with an investment advisor?
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M17.25 8.25001H9.74999V0.749988C9.74999 0.335777 9.41421 0 9 0C8.58579 0 8.25001 0.335777 8.25001 0.749988V8.24998H0.749988C0.335777 8.25001 0 8.58579 0 9C0 9.41421 0.335777 9.74999 0.749988 9.74999H8.24998V17.25C8.24998 17.6642 8.58575 18 8.99996 18C9.41417 18 9.74995 17.6642 9.74995 17.25V9.74999H17.2499C17.6642 9.74999 17.9999 9.41421 17.9999 9C18 8.58579 17.6642 8.25001 17.25 8.25001Z"
                  fill="#02364A"
                />
              </svg>
            </div>
            <div className="box">
              Is Retired App Phase 1 available on mobile devices?
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M17.25 8.25001H9.74999V0.749988C9.74999 0.335777 9.41421 0 9 0C8.58579 0 8.25001 0.335777 8.25001 0.749988V8.24998H0.749988C0.335777 8.25001 0 8.58579 0 9C0 9.41421 0.335777 9.74999 0.749988 9.74999H8.24998V17.25C8.24998 17.6642 8.58575 18 8.99996 18C9.41417 18 9.74995 17.6642 9.74995 17.25V9.74999H17.2499C17.6642 9.74999 17.9999 9.41421 17.9999 9C18 8.58579 17.6642 8.25001 17.25 8.25001Z"
                  fill="#02364A"
                />
              </svg>
            </div>
            <div className="box">
              How secure is my financial information on Retired?
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M17.25 8.25001H9.74999V0.749988C9.74999 0.335777 9.41421 0 9 0C8.58579 0 8.25001 0.335777 8.25001 0.749988V8.24998H0.749988C0.335777 8.25001 0 8.58579 0 9C0 9.41421 0.335777 9.74999 0.749988 9.74999H8.24998V17.25C8.24998 17.6642 8.58575 18 8.99996 18C9.41417 18 9.74995 17.6642 9.74995 17.25V9.74999H17.2499C17.6642 9.74999 17.9999 9.41421 17.9999 9C18 8.58579 17.6642 8.25001 17.25 8.25001Z"
                  fill="#02364A"
                />
              </svg>
            </div>
            <div className="box">
              How secure is my financial information on Retired?
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M17.25 8.25001H9.74999V0.749988C9.74999 0.335777 9.41421 0 9 0C8.58579 0 8.25001 0.335777 8.25001 0.749988V8.24998H0.749988C0.335777 8.25001 0 8.58579 0 9C0 9.41421 0.335777 9.74999 0.749988 9.74999H8.24998V17.25C8.24998 17.6642 8.58575 18 8.99996 18C9.41417 18 9.74995 17.6642 9.74995 17.25V9.74999H17.2499C17.6642 9.74999 17.9999 9.41421 17.9999 9C18 8.58579 17.6642 8.25001 17.25 8.25001Z"
                  fill="#02364A"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="footers">
          <div className="box-table">
            <div className="box">
              <div className="header">Services</div>
              <div className="text">Overview</div>
              <div className="text">Lorem ipsum</div>
              <div className="text">Lorem ipsum</div>
              <div className="text">Lorem ipsum</div>
            </div>
            <div className="box">
              <div className="header">Services</div>
              <div className="text">Overview</div>
              <div className="text">Lorem ipsum</div>
            </div>
            <div className="box">
              <div className="header">Services</div>
              <div className="text">Overview</div>
              <div className="text">Lorem ipsum</div>

              <div className="text">Lorem ipsum</div>
            </div>
            <div className="box">
              <div className="header">Services</div>
              <div className="text">Overview</div>
              <div className="text">Lorem ipsum</div>
              <div className="text">Lorem ipsum</div>
              <div className="text">Lorem ipsum</div>
            </div>
            <div className="box">
              <div className="header">Services</div>
              <div className="text">Overview</div>
              <div className="text">Lorem ipsum</div>
              <div className="text">Lorem ipsum</div>
            </div>

            <div className="box">
              <div className="header">Download app at</div>
              <img
                src={andriodlogo}
                style={{
                  marginBottom: "1rem",
                  marginTop: "0.7rem",
                  display: "block",
                }}
              />
              <img src={apple} />
            </div>
          </div>
          <div className="box-bottom-table">
            <div className="footerleftside">
              <div className="text-sub">Subscribe to never miss updates</div>
              <div className="input-box">
                <input placeholder="Enter mail" />
                <div className="Subscribe">Subscribe</div>
              </div>
            </div>
            <div>
              <div className="getintouch">Get in touch</div>
              <div className="email">myclarity@gmail.com</div>
              <div className="imgsect">
                <img src={a1} />

                <img src={a3} />
                <img src={a4} />
                <img src={a5} />
                <img src={a6} />
              </div>
            </div>
          </div>
          <div className="bottom-copty">
            <div className="copyrights">
              Copyright 2024 My clarity Private Limited-All Rights reserved
            </div>
            <div className="righstideunderline">
              <div>Terms & conditions</div>
              <div>Privacy Policy</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const cssstyle = `
.containers {

}
h3 {
    background: #5f9ea0;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
}
.slick-next:before, .slick-prev:before {
    color: #000;
}
.center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    -ms-transform: scale(1.08);
    transform: scale(1.08);
}
.center h3 {
    transition: all .10s ease;
}
`;