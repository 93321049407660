import React, { useContext } from "react";
import "./legacydesigns.css";

import Eightsection from "./sections/Eigthsection/Eightsection";
import Eleventhsection from "./sections/Eleventhsection/Eleventhsection";
import Fourthsection from "./sections/Fourthsection/Fourthsection";
import Secondsection from "./sections/Secondsection/Secondsection";
import Sixthsection from "./sections/Sixthsection/Sixthsection";
import ThirdSection from "./sections/Thirdsection/ThirdSection";
import Twelfthsection from "./sections/Twelfth/Twelfthsection";
import Faq from "./sections/Faq/Faq";
import Rotatecard from "../3dcard/Rotatecard";
import { cards2 } from "../../assets/data/cards";
import { retiredapp } from "../../assets/Func/navigations";
import { Link } from "react-router-dom";
import { Globalcontext } from "../../contexts/GlobalContext";

import { sidebar } from "../../assets/data/cards";
import { bonds } from "../../assets/Func/navigations";
import Navbar from "../Navbar/Navbar/Navbar";

const Legacydesigns = () => {
  const { sideBarStatus } = useContext(Globalcontext);
  return (
    <section style={{ position: "relative" }}>
      <div
        className="main-sidebar"
        style={{
          transform: sideBarStatus ? "" : "translateX(-100vw)",
        }}
      >
        {sidebar.map((data) => {
          return <Eachsidebarcard key={data.title} data={data} />;
        })}
      </div>
      <Navbar />
      <Secondsection />
      <ThirdSection />
      <Fourthsection />
      <div className="landing-center-para">
        Our investment portfolio provides unique and diverse opportunities to
        grow your money with low-volatility and secure returns.
      </div>
      <Sixthsection />
      <div className="landing-center-para landing-center-para2">
        READY for a Predictable Fixed Income Investment Opportunity on Retired
        App’s Platform?
        <div onClick={retiredapp}>Get Started</div>
      </div>
      <Eightsection />
      <div className="ninth-section">
        {cards2.map((eachcard) => {
          return (
            <Link to="/coach">
              <Rotatecard eachcard={eachcard} />
            </Link>
          );
        })}
      </div>
      <div className="landing-center-para landing-center-para3">
        If you want to list your self as a COACH on Retired App’s Platform?
        <div onClick={retiredapp}>Get Started</div>
      </div>
      <Eleventhsection />
      <Twelfthsection />
      <Faq />
    </section>
  );
};

export default Legacydesigns;

const Eachsidebarcard = ({ data }) => {
  let logoImage =
    require(`../../assets/Images/rotatecards/${data.image}.svg`).default;
  return (
    <div className="eachsidebarcard" onClick={bonds}>
      <div>
        <img src={logoImage} alt={data.image} />
      </div>
      <div>
        <div>{data.title}</div>
        <div>{data.slogan}</div>
      </div>
    </div>
  );
};
