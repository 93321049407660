import dashboard from "../Images/AdvisorSidebar/dashboard.svg";
import crm from "../Images/AdvisorSidebar/crm.svg";
import marketplace from "../Images/AdvisorSidebar/marketplace.svg";
import marketing from "../Images/AdvisorSidebar/marketing.svg";
import team from "../Images/AdvisorSidebar/team.svg";
import inbox from "../Images/AdvisorSidebar/inbox.svg";
import workflow from "../Images/AdvisorSidebar/workflow.svg";
import meetings from "../Images/AdvisorSidebar/meetings.svg";
import feed from "../Images/AdvisorSidebar/feed.svg";
import documents from "../Images/AdvisorSidebar/documents.svg";

export const allAdvisorSidebar = [
  {
    name: "Dashboard",
    icon: dashboard,
    subMenu: [
      { name: "Snapshot", link: "/advisor-profile" },
      { name: "Reporting" },
    ],
  },
  {
    name: "CRM",
    icon: crm,
    subMenu: [
      { name: "Clients", link: "/advisor-client" },
      { name: "Leads", link: "/advisor-leads" },
    ],
  },
  {
    name: "Marketplace",
    icon: marketplace,
    subMenu: [
      { name: "Asset Classes", link: "/marketplace/assetclasses" },
      { name: "Sub-Asset Classes", link: "/marketplace/subassetclasses" },
      { name: "Companies", link: "/marketplace/companies" },
      { name: "Products", link: "/marketplace/products" },
      { name: "Services", link: "/marketplace/services" },
    ],
  },
  {
    name: "Marketing",
    icon: marketing,
    subMenu: [
      { name: "Website" },
      { name: "Campaigns" },
      { name: "Funnels" },
      { name: "Affiliates" },
      { name: "Content", link: "/advisor-library/articles" },
      { name: "Social Media", link: "/advisor-library/social-content" },
    ],
  },
  { name: "Team", icon: team, subMenu: "" },
  { name: "Inbox", icon: inbox, subMenu: "" },
  { name: "Workflow", icon: workflow, subMenu: "" },
  { name: "Meetings", icon: meetings, subMenu: "" },
  { name: "Feed", icon: feed, subMenu: "" },
  { name: "Documents", icon: documents, subMenu: "" },
];
