import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./login.scss";
import dashboardNavbarLogo from "../../assets/logo/dashboardNavbarLogo.svg";
import CustomInput from "../../components/Custom";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { z } from "zod";
import axios from "axios";
import { toast } from "react-toastify";

export default function Sogin() {
  const history = useHistory();
  const location = useLocation();

  const isClientRoute = location.pathname.includes("client");
  const isAdvisorRoute = location.pathname.includes("advisor");

  // state values
  const [currentStep, setCurrentStep] = useState(1); //default step "1"
  const [localLoading, setLocalLoading] = useState(false);
  const [emailVerifyCode, setEmailVerifyCode] = useState("");
  const [signupValues, setSignupValues] = useState({
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordRequirements, setPasswordRequirements] = useState({
    hasCapitalLetter: false,
    hasSpecialCharacter: false,
    hasMinLength: false,
    hasNumber: false,
  });
  const [isAvailableState, setIsAvailableState] = useState({
    email: false,
    username: false,
  });

  //all validations
  const emailValidation = z.string().email();

  const passwordSchema = z
    .string()
    .min(10, "Minimum Ten Characters")
    .regex(/[A-Z]/, "Minimum One Capital Letter")
    .regex(/[!@#$%^&*(),.?":{}|<>]/, "Minimum One Special Character")
    .regex(/[0-9]/, "Minimum One Number");

  useEffect(() => {
    const password = signupValues.confirmPassword;

    setPasswordRequirements({
      hasCapitalLetter: /[A-Z]/.test(password),
      hasSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      hasMinLength: password?.length >= 10,
      hasNumber: /[0-9]/.test(password),
    });
  }, [signupValues.confirmPassword]);

  //functions

  async function isAvailable(URL, name) {
    try {
      let response = await axios.get(URL);

      if (name === "username") {
        if (!response?.data?.status) {
          setIsAvailableState((prev) => {
            return { ...prev, username: true };
          });
        } else {
          setIsAvailableState((prev) => {
            return { ...prev, username: false };
          });
        }
      } else if (name === "email") {
        if (response?.data?.status && response?.data?.count === 0) {
          setIsAvailableState((prev) => {
            return { ...prev, email: true };
          });
        } else {
          setIsAvailableState((prev) => {
            return { ...prev, email: false };
          });
        }
      }
    } catch (error) {
      console.log(error?.message, `Is available ${name} error`);
    }
  }

  async function initiateRegistration() {
    setLocalLoading(true);
    let obj = {
      ref_affiliate: "6535e2f65958ffca65b865ef21fde26f0b8406ca",
      signedup_app: "gxpulse",
      username: signupValues?.username,
      email: signupValues?.email,
      password: signupValues?.password,
    };

    try {
      let response = await axios.post(
        "https://gxauth.apimachine.com/gx/user/signup",
        obj
      );

      if (response?.data?.status) {
        toast("Please check you email for code");
        setCurrentStep(2);
      } else {
        toast(response?.data?.message);
      }
      setLocalLoading(false);
    } catch (error) {
      setLocalLoading(false);
      console.log(error?.message, `User registration error`);
    }
  }

  async function verifyEmail() {
    setLocalLoading(true);
    let obj = {
      email: signupValues?.email,
      code: emailVerifyCode,
    };

    try {
      let response = await axios.post(
        "https://gxauth.apimachine.com/gx/user/confirm",
        obj
      );

      toast(response?.data?.message);

      if (response?.data?.status) {
        toast("User registered successfully!");
        if (window?.location?.pathname?.includes("advisor")) {
          history.push("/advisor/login");
        } else {
          history.push("/client/login");
        }
      } else {
        toast(response?.data?.message);
      }
      setLocalLoading(false);
    } catch (error) {
      setLocalLoading(false);
      console.log(error?.message, `User registration error`);
    }
  }

  //renderings

  useEffect(() => {
    if (signupValues?.username) {
      isAvailable(
        `https://comms.globalxchange.io/user/profile/data/get?username=${signupValues?.username}`,
        "username"
      );
    }
  }, [signupValues?.username]);

  useEffect(() => {
    if (signupValues?.email) {
      isAvailable(
        `https://comms.globalxchange.io/user/profile/data/get?email=${signupValues?.email}`,
        "email"
      );
    }
  }, [signupValues?.email]);

  return (
    <div className="loginPage">
      <div className="endside"></div>
      <div className="right">
        <div className="logintext">
          {/* {isClientRoute ? "CLIENT SIGNUP" : "ADVISOR SIGNUP"} */}
          <img src={dashboardNavbarLogo} alt="dashboardNavbarLogo" />
        </div>
        {/* <div className="input-section">
          <input placeholder="Username" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="23"
            viewBox="0 0 22 23"
            fill="none"
          >
            <g clip-path="url(#clip0_1704_50)">
              <path
                d="M19.25 3.25H12.375V0.5H9.625V3.25H2.75C2.02065 3.25 1.32118 3.53973 0.805456 4.05546C0.289731 4.57118 0 5.27065 0 6L0 19.75H22V6C22 5.27065 21.7103 4.57118 21.1945 4.05546C20.6788 3.53973 19.9793 3.25 19.25 3.25ZM19.25 17H2.75V6H19.25V17Z"
                fill="#666666"
              />
              <path
                d="M7.33325 7.83301H4.58325V10.583H7.33325V7.83301Z"
                fill="#666666"
              />
              <path
                d="M11.9167 7.83301H9.16675V10.583H11.9167V7.83301Z"
                fill="#666666"
              />
              <path
                d="M17.4167 7.83301H13.75V10.583H17.4167V7.83301Z"
                fill="#666666"
              />
              <path
                d="M11.9166 12.417H4.58325V15.167H11.9166V12.417Z"
                fill="#666666"
              />
              <path
                d="M17.4167 12.417H13.75V15.167H17.4167V12.417Z"
                fill="#666666"
              />
            </g>
            <defs>
              <clipPath id="clip0_1704_50">
                <rect
                  width="22"
                  height="22"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="input-section">
          <input placeholder="Email" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
          >
            <path
              d="M19.25 0.416992H2.75C2.02065 0.416992 1.32118 0.706723 0.805456 1.22245C0.289731 1.73817 0 2.43765 0 3.16699L0 20.5837H22V3.16699C22 2.43765 21.7103 1.73817 21.1945 1.22245C20.6788 0.706723 19.9793 0.416992 19.25 0.416992ZM19.0428 3.16699L12.5024 9.70741C12.0977 10.0939 11.5596 10.3096 11 10.3096C10.4404 10.3096 9.90228 10.0939 9.49758 9.70741L2.95717 3.16699H19.0428ZM2.75 17.8337V6.84924L7.55242 11.6517C8.00503 12.1045 8.54242 12.4637 9.13389 12.7087C9.72536 12.9538 10.3593 13.0799 10.9995 13.0799C11.6398 13.0799 12.2737 12.9538 12.8652 12.7087C13.4567 12.4637 13.9941 12.1045 14.4467 11.6517L19.25 6.84924V17.8337H2.75Z"
              fill="#666666"
            />
          </svg>
        </div>

        <div className="input-section">
          <input placeholder="Password" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="23"
            viewBox="0 0 20 23"
            fill="none"
          >
            <path
              d="M16.4167 7.83297V7.05472C16.4167 5.35292 15.7407 3.72081 14.5373 2.51746C13.334 1.3141 11.7018 0.638062 10 0.638062C8.29824 0.638062 6.66613 1.3141 5.46277 2.51746C4.25941 3.72081 3.58337 5.35292 3.58337 7.05472V7.83297H0.833374V19.7496C0.833374 20.479 1.12311 21.1784 1.63883 21.6942C2.15456 22.2099 2.85403 22.4996 3.58337 22.4996H16.4167C17.1461 22.4996 17.8455 22.2099 18.3613 21.6942C18.877 21.1784 19.1667 20.479 19.1667 19.7496V7.83297H16.4167ZM6.33337 7.05472C6.33337 6.08226 6.71968 5.14963 7.40732 4.462C8.09495 3.77437 9.02758 3.38806 10 3.38806C10.9725 3.38806 11.9051 3.77437 12.5928 4.462C13.2804 5.14963 13.6667 6.08226 13.6667 7.05472V7.83297H6.33337V7.05472ZM3.58337 19.7496V10.583H16.4167V19.7496H3.58337Z"
              fill="#666666"
            />
          </svg>
        </div> */}
        <div className="signUpContainer">
          <CustomInput
            placeholder="Email"
            availableContainer={true}
            available={
              isAvailableState?.email &&
              emailValidation?.safeParse(signupValues?.email)?.success
            }
            stateValue={signupValues}
            setState={setSignupValues}
            name="email"
          />
          <CustomInput
            placeholder="Username"
            availableContainer={true}
            available={
              isAvailableState?.username && signupValues?.username?.length > 0
            }
            stateValue={signupValues}
            setState={setSignupValues}
            name="username"
          />
          <div className="flexContainer">
            <input
              type="password"
              placeholder="password"
              onChange={(event) => {
                setSignupValues((prev) => {
                  return { ...prev, password: event?.target?.value };
                });
              }}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              onChange={(event) => {
                setSignupValues((prev) => {
                  return { ...prev, confirmPassword: event?.target?.value };
                });
              }}
            />
          </div>
          {currentStep === 1 && (
            <div className="passwordRequirments">
              <div className="title">See Password Requirements</div>
              <div className="allRequirements">
                <div className="eachRequirement">
                  <IoIosCheckmarkCircle
                    className={
                      passwordRequirements.hasCapitalLetter
                        ? "requirementMet"
                        : ""
                    }
                  />
                  <span>Minimum One Capital Letter</span>
                </div>
                <div className="eachRequirement">
                  <IoIosCheckmarkCircle
                    className={
                      passwordRequirements.hasSpecialCharacter
                        ? "requirementMet"
                        : ""
                    }
                  />
                  <span>Minimum One Special Character</span>
                </div>
                <div className="eachRequirement">
                  <IoIosCheckmarkCircle
                    className={
                      passwordRequirements.hasMinLength ? "requirementMet" : ""
                    }
                  />
                  <span>Minimum Ten Characters</span>
                </div>
                <div className="eachRequirement">
                  <IoIosCheckmarkCircle
                    className={
                      passwordRequirements.hasNumber ? "requirementMet" : ""
                    }
                  />
                  <span>Minimum One Number</span>
                </div>
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <CustomInput
              title="Verify your email "
              placeholder="Email verification code"
              stateValue={emailVerifyCode}
              setState={setEmailVerifyCode}
            />
          )}
        </div>

        <div className="forgotsection">
          {/* <div className="text"></div> */}
          <label
            className={
              localLoading
                ? "notclickable"
                : signupValues?.password === signupValues?.confirmPassword &&
                  isAvailableState?.username &&
                  isAvailableState?.email
                ? ""
                : "notclickable"
            }
            onClick={() => {
              if (currentStep === 1) {
                initiateRegistration();
              } else if (currentStep === 2) {
                verifyEmail();
              }
            }}
          >
            {localLoading ? "Loading..." : "Next Step"}
          </label>
        </div>
        {/* <div className='socialmedia'>
                    <div>
                        <div className='texttoconti'>Or continue with</div>
                        <div className='socailasection'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                                <g filter="url(#filter0_d_1704_29)">
                                    <rect x="3" y="2" width="45" height="45" rx="22.5" fill="#ECFAFF" />
                                    <rect x="3.5" y="2.5" width="44" height="44" rx="22" stroke="#5A1740" stroke-opacity="0.16" />
                                </g>
                                <path d="M34.2891 34.2355C37.3314 31.396 38.6836 26.6634 37.8723 22.1337H25.7704V27.1367H32.6665C32.396 28.7593 31.4495 30.1114 30.0974 30.9903L34.2891 34.2355Z" fill="#4285F4" />
                                <path d="M14.2771 30.2466C15.1664 31.9986 16.4419 33.5259 18.0074 34.7132C19.5729 35.9006 21.3874 36.7171 23.3143 37.1011C25.2412 37.4852 27.2302 37.4269 29.1313 36.9305C31.0323 36.4341 32.7959 35.5126 34.2891 34.2355L30.0974 30.9903C26.5141 33.3566 20.5646 32.4777 18.5364 26.9338L14.2771 30.2466Z" fill="#34A853" />
                                <path d="M18.5364 26.9339C17.9955 25.2437 17.9955 23.6887 18.5364 21.9985L14.2771 18.6857C12.7221 21.7956 12.2488 26.1902 14.2771 30.2467L18.5364 26.9339Z" fill="#FBBC02" />
                                <path d="M18.5364 21.9985C20.0237 17.3335 26.3789 14.6292 30.6382 18.6181L34.3567 14.9672C29.0832 9.8966 18.8068 10.0994 14.2771 18.6857L18.5364 21.9985Z" fill="#EA4335" />
                                <defs>
                                    <filter id="filter0_d_1704_29" x="0" y="0" width="53" height="53" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dx="1" dy="2" />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1704_29" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1704_29" result="shape" />
                                    </filter>
                                </defs>
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                                <g filter="url(#filter0_d_1704_36)">
                                    <rect x="3" y="2" width="45" height="45" rx="22.5" fill="#ECFAFF" />
                                    <rect x="3.5" y="2.5" width="44" height="44" rx="22" stroke="#5A1740" stroke-opacity="0.16" />
                                </g>
                                <path d="M27.5959 38.0215V17.8067C27.5959 16.3869 28.0015 15.4404 29.9621 15.4404H32.5312V11.1811C32.058 11.1135 30.5706 10.9783 28.8128 10.9783C25.162 10.9783 22.6605 13.2093 22.6605 17.3334V38.0215M32.3284 20.8491H18.4688V25.7169H31.72" fill="#3B5998" />
                                <defs>
                                    <filter id="filter0_d_1704_36" x="0" y="0" width="53" height="53" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dx="1" dy="2" />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1704_36" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1704_36" result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div> */}
      </div>
      <div className="left">
        <div className="dontacount">Already Have An Account?</div>
        <div
          className="signup"
          onClick={() =>
            history.push(isClientRoute ? "/client/login" : "/advisor/login")
          }
        >
          Login
        </div>
      </div>
    </div>
  );
}
