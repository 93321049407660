import React, { useState } from "react";

export default function UnderReviwe({ such }) {
  console.log("such3234", such);
  function toCardinal(num) {
    var ones = num % 10;
    var tens = num % 100;

    if (tens < 11 || tens > 13) {
      switch (ones) {
        case 1:
          return num + "st";
        case 2:
          return num + "nd";
        case 3:
          return num + "rd";
      }
    }

    return num + "th";
  }
  var monthNames = [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [tablist, settablist] = useState("Mutual Funds");

  let finalstart = such?.PlanDetails?.[0]?.date_of_accomplishment?.split(",");
  let resultstart = finalstart?.[0]?.split("/");

  return (
    <>
      <div className="plantable">
        <div className="leftpanb">
          <div className="planName">PlanB Required Amount</div>
          <div className="plancurrecy">
            {such?.PlanDetails?.[0]?.CurrencyForTheAmount == "USD" ? "$" : "₹"}
            {such?.PlanDetails?.[0]?.amountToAccomplish}
          </div>
        </div>
        <div className="full-right-side-planb">
          <div className="rightsidetable">
            <div className="monthaccomplish">Months To Accomplish</div>
            <div className="planbcurrenyvalue">
              {such?.PlanDetails?.[0]?.remainingMonths}
            </div>
          </div>
          <div className="monthplanb">
            {monthNames[resultstart?.[0]?.replace(/^0+/, "")]}{" "}
            {toCardinal(resultstart?.[1]?.replace(/^0+/, ""))}{" "}
            {resultstart?.[2]}
          </div>
        </div>
      </div>
      <div className="plabbtable-section">
        <table
          style={{
            marginBottom: "2rem",
            border: "1px solid #E5E5E5",
          }}
        >
          <tr>
            <th>Product</th>

            <th>Avg rate of return</th>
            <th>Contribution Frequency</th>
            <th>% of total target investment</th>
            <th>Asset value at target date</th>
            <th>Number Of Months</th>
            <th>Monthly Contribution</th>
            <th>Total Contribution</th>
            <th>% of total contribution</th>
            <th>Approved Status</th>
          </tr>

          {such?.productDetails?.map((item, key) => {
            return (
              <tr>
                <td>
                  <div className="dropdown-planb">{item?.productName}</div>
                </td>
                <td>
                  {item?.fields?.map((each, j) => {
                    if (each?.expectedROI)
                      return (
                        <div className="text-handle">{each?.expectedROI}</div>
                      );
                  })}
                </td>

                <td>
                  {item?.fields?.map((each, j) => {
                    if (each?.contributionFrequency)
                      return (
                        <div className="text-handle">
                          {each?.contributionFrequency}
                        </div>
                      );
                  })}
                </td>

                <td>
                  {item?.fields?.map((each, j) => {
                    if (each?.contributionpercentage)
                      return (
                        <div className="text-handle">
                          {each?.contributionpercentage}
                        </div>
                      );
                  })}
                </td>

                <td>
                  {item?.fields?.map((each, j) => {
                    if (each?.expectedbalance)
                      return (
                        <div className="text-handle">
                          {each?.expectedbalance}
                        </div>
                      );
                  })}
                </td>

                <td>
                  {item?.fields?.map((each, j) => {
                    if (each?.termlength)
                      return (
                        <div className="text-handle">{each?.termlength}</div>
                      );
                  })}
                </td>

                <td>
                  {item?.fields?.map((each, j) => {
                    if (each?.monthlycontribution)
                      return (
                        <div className="text-handle">
                          {each?.monthlycontribution}
                        </div>
                      );
                  })}
                </td>

                <td>
                  {item?.fields?.map((each, j) => {
                    if (each?.totalinvestmentamount)
                      return (
                        <div className="text-handle">
                          {Number(each?.totalinvestmentamount)?.toFixed(2)}
                        </div>
                      );
                  })}
                </td>

                <td>
                  {item?.fields?.map((each, j) => {
                    if (each?.allocationpercentage)
                      return (
                        <div className="text-handle">
                          {Number(each?.allocationpercentage)?.toFixed(2)}
                        </div>
                      );
                  })}
                </td>

                <td>
                  <div
                    className="underReview"
                    style={{
                      background:
                        such?.proposalStatus == "submitted"
                          ? "#FF9100"
                          : such?.proposalStatus == "approved"
                          ? "#1BA27A"
                          : such?.proposalStatus == "rejected"
                          ? "#EA0F0F"
                          : such?.proposalStatus == "request for resubmission"
                          ? "#58B135"
                          : "#FF9100",
                    }}
                  >
                    {such?.proposalStatus == "submitted"
                      ? "Under review"
                      : such?.proposalStatus == "approved"
                      ? "Approved"
                      : such?.proposalStatus == "rejected"
                      ? "Rejected"
                      : such?.proposalStatus == "request for resubmission"
                      ? "Request For Resubmission"
                      : ""}
                    {/* */}
                  </div>
                </td>
                {/* <td

>
 
    {
        item?.fields?.map((each, j) => {
            if (each?.totalinvestmentamount)
              
            return (


                <div className='text-handle'>
                    {Number(each?.totalinvestmentamount)?.toFixed(2)}
                </div>
            )
        })
    }
</td> */}
              </tr>
            );
          })}
        </table>
      </div>
    </>
  );
}
