export const card1 = [
  {
    title: "Seasonal Get Aways",
    content:
      "Bonds which start from 3 -6 months helps to acehive your best short term goals.",
    image: "moneymarkets",
  },
  {
    title: "Kick Start Your Hobbies",
    content:
      "Bonds which start from 6months-1 year helps to focus on your passion and invest in yourself for your growth.",
    image: "indexfunds",
  },
  {
    title: "Family Support",
    content:
      "Bonds which start from 1-3 years helps to focus on your passion and save money for your family lifestyle.",
    image: "funds",
  },
  {
    title: "Boost Retirement Savings",
    content:
      "Bonds which start from 6 months-3years can help all the senior citizens to boost their retirement savings to get monthly pensions.",
    image: "sharetokens",
  },
  {
    title: "NRI Eligible bonds",
    content:
      "Who left India can invest in next unicorns of India in tokenized shares of the startups.",
    image: "bonds",
  },
];

export const cards2 = [
  {
    title: "Find a Career Coach",
    content:
      "If you are a career coach & Get whitelisted to be listed in Retired App to be a Career Coach for your people.",
    image: "careercoach",
  },
  {
    title: "Find a Life Coach",
    content:
      "If you are a life coach & Get whitelisted to be listed in Retired App to be a life Coach for your people.",
    image: "lifecoach",
  },
];

export const sidebar = [
  {
    title: "Seasonal Get Aways",
    image: "moneymarkets",
    slogan: "Invest for your short term dreams.",
  },
  {
    title: "Kick Start Your Hobbies",
    image: "indexfunds",
    slogan: "Invest for your hobbies to become live.",
  },
  {
    title: "Family Support",
    image: "funds",
    slogan: "Invest For your Long term goals.",
  },
  {
    title: "Retirement Bonds",
    image: "sharetokens",
    slogan: "Invest For your passive and your Financial Freedom.",
  },
  {
    title: "NRI Eligible bonds",
    image: "bonds",
    slogan: "Invest in Next Indian Unicorn.",
  },
  {
    title: "Invite & Earn",
    image: "affiliate",
    slogan: "Invite your friends to the platform for more exclusive rewards..",
  },
];
