import React, { useEffect, useState } from 'react'
import './MenuList.scss'
import { useHistory, useParams } from 'react-router-dom';
export default function MenuList() {
    const [active, setactive] = useState('')
    const { id } = useParams();
    let history = useHistory()
    useEffect(() => {
        setactive(id)
    }, [id])
    return (
        <div className='leftSideMenu'>
            <div className='logoMenu'

            >
                <svg
                    onClick={() => history.push('/')}
                    xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                    <path d="M21 0C13.5104 0 6.93775 3.92147 3.22007 9.8227H25.0229C30.1653 9.8227 34.3486 14.006 34.3486 19.1485C34.3486 24.2908 30.1653 28.4743 25.0229 28.4743H23.0012L30.3787 39.793C37.2684 36.3477 42 29.2271 42 21C42 9.40203 32.5979 0 21 0ZM23.5306 41.8478C22.7007 41.9472 21.8565 42 21 42C9.40203 42 0 32.5979 0 21C0 19.5011 0.158273 18.0397 0.456694 16.6297H25.0229C26.4117 16.6297 27.5417 17.7598 27.5417 19.1485C27.5417 20.5371 26.4117 21.6669 25.0229 21.6669H10.3963L23.5306 41.8478Z" fill="#58B135" />
                </svg>
            </div>

            <div className='bottmlistmenu'

            >
                <div className='itemlistimg'
                    style={{
                        border: active == 'individuals' && "1px solid #E5E5E5 "
                    }}
                    onClick={() => history.push('/advisors/individuals')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22" fill="none">
                        <path d="M18.0778 15.7889C18.0974 15.8085 18.116 15.8287 18.1349 15.8488C18.1448 15.8578 18.155 15.8661 18.1646 15.8756C18.9677 16.6036 20.0324 15.5171 19.2895 14.7177L19.1232 14.5514C19.1214 14.5496 19.1197 14.548 19.1181 14.5463L13.9458 9.37407L13.306 10.0139C12.2706 11.0949 10.4109 11.1359 9.32483 10.1009C8.1101 9.03329 8.08473 7.00608 9.2679 5.90888L11.329 3.90918L5.34542 0.260576C5.05449 0.0831743 4.67999 0.12794 4.43906 0.368874L0.216225 4.59171C-0.0128821 4.82082 -0.0658092 5.17259 0.0857394 5.45902L2.62312 10.2541L3.32031 9.55694C4.47356 8.40701 6.30124 8.55006 7.34219 9.77447C7.78491 10.2172 8.01612 10.7923 8.03679 11.3736C8.65031 11.4236 9.24991 11.6822 9.71829 12.1505L9.93601 12.3682C10.3657 12.7979 10.6105 13.3631 10.6316 13.9673C12.8849 14.1344 13.9308 16.943 12.312 18.5485L11.5234 19.337L13.0266 20.8402C13.2576 21.0712 13.5888 21.1656 13.9223 21.083C14.6236 20.9032 14.8361 19.9959 14.3415 19.5252C14.0072 19.1909 14.0073 18.649 14.3415 18.3147L14.3416 18.3147C14.6597 17.9358 15.1944 17.9346 15.5342 18.2957C15.9422 18.7266 16.6609 18.6782 16.9989 18.1502C17.231 17.7927 17.167 17.2883 16.8673 16.9994C16.533 16.6651 16.533 16.1232 16.8673 15.7889C17.2015 15.4547 17.7435 15.4546 18.0778 15.7889Z" fill="#110F0D" />
                        <path d="M2.90026 14.7592C3.16158 14.7592 3.4072 14.6575 3.59193 14.4727L5.91418 12.1505C5.91433 12.1503 5.91452 12.1501 5.91472 12.15L6.1319 11.9328C6.39321 11.6715 6.39321 11.2463 6.1319 10.9849L5.91423 10.7673C5.55009 10.3878 4.89503 10.3879 4.53094 10.7672L2.20869 13.0895C1.8273 13.4709 1.82735 14.0914 2.20869 14.4728C2.39337 14.6575 2.639 14.7592 2.90026 14.7592Z" fill="#110F0D" />
                        <path d="M21.4302 11.1532C22.6326 10.0294 23.9497 6.98341 24.9227 5.2762C25.088 4.9861 25.0394 4.62133 24.8018 4.3867L20.5756 0.212981C20.3354 -0.0241894 19.965 -0.068271 19.6759 0.105905L16.0754 2.27519C15.3663 2.5969 14.707 3.00761 14.1505 3.55687L10.4591 7.13828C10.0421 7.52543 9.98142 8.224 10.3347 8.67732C10.7462 9.24388 11.6127 9.30307 12.0955 8.80351L13.3406 7.55842C13.6748 7.22415 14.2168 7.22415 14.551 7.55842L19.7422 12.7495L21.4302 11.1532Z" fill="#110F0D" />
                        <path d="M11.1016 17.338C11.4829 16.9566 11.4829 16.3361 11.1016 15.9547C10.7376 15.5753 10.0824 15.5753 9.71836 15.9547L7.39611 18.2769C7.01477 18.6583 7.01477 19.2788 7.39611 19.6602C7.77745 20.0415 8.39792 20.0416 8.77935 19.6602L11.1016 17.338Z" fill="#110F0D" />
                        <path d="M5.49397 17.3529C5.75528 17.3529 6.00091 17.2512 6.18564 17.0665L8.72556 14.5265C8.98555 14.2771 8.9856 13.8281 8.72556 13.5787L8.50784 13.361C8.12665 12.9798 7.50628 12.9797 7.12484 13.3608L4.8023 15.6832C4.42096 16.0646 4.42096 16.6851 4.8023 17.0665C4.98703 17.2512 5.23266 17.3529 5.49397 17.3529Z" fill="#110F0D" />
                    </svg>

                    <div className='showdathover'>Advisors</div>
                </div>

                <div className='itemlistimg'
                    style={{
                        border: active == 'firms' && "1px solid #E5E5E5 "
                    }}
                    onClick={() => history.push('/advisors/firms')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                        <path d="M16.377 9.40375H17.7812V22.0301H23.0441V9.40375H24.4777C24.7746 9.40375 25.0152 9.16312 25.0152 8.86631V6.21675C25.0152 6.0651 24.9511 5.92057 24.8389 5.81871L20.7738 2.1315C20.5683 1.94521 20.255 1.94575 20.0502 2.13277L16.0145 5.82003C15.9031 5.92184 15.8395 6.06583 15.8395 6.2168V8.86636C15.8395 9.16312 16.0802 9.40375 16.377 9.40375Z" fill="#110F0D" />
                        <path d="M15.6318 22.0301V13.2509C15.6318 12.954 15.3912 12.7134 15.0944 12.7134H10.9064C10.6096 12.7134 10.369 12.954 10.369 13.2509V22.0301H15.6318Z" fill="#110F0D" />
                        <path d="M24.2341 23.5343H1.66591C1.55534 23.5343 1.46573 23.4447 1.46573 23.3341V14.1143H3.8047C4.79442 14.0784 4.80834 12.6955 3.82542 12.6485H1.46573V11.3772H2.87401C3.86382 11.3412 3.87755 9.95829 2.89473 9.91143H1.46573V8.64006H2.87401C3.86382 8.6041 3.87755 7.22118 2.89473 7.17432H1.46573V5.90294H2.87401C3.86382 5.86698 3.87755 4.48406 2.89473 4.43721H1.46573V3.16578H3.8047C4.79442 3.12987 4.80834 1.74695 3.82542 1.70005H1.46573V0.750152C1.42978 -0.239708 0.0468546 -0.253339 0 0.729436V24.2671C0 24.6719 0.328129 25 0.732867 25H24.2548C25.2382 24.9529 25.2234 23.57 24.2341 23.5343Z" fill="#110F0D" />
                        <path d="M3.16132 16.2475V22.0301H8.42418V16.2475C8.42418 15.9507 8.18356 15.7101 7.88675 15.7101H3.69875C3.40189 15.7101 3.16132 15.9507 3.16132 16.2475Z" fill="#110F0D" />
                    </svg>

                    <div className='showdathover'>Firms</div>
                </div>
            </div>
        </div>
    )
}
